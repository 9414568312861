import { Analytics } from '@vercel/analytics/react'
import '../styles/globals.css'
import SlideOut from '../components/SlideOut'
import { SlideOutProvider } from '../providers/SlideOutContext'

import loadable from '@loadable/component'
const Head = loadable(() => import('next/head'))
const Layout = loadable(() => import('../components/Layout'))

const Rokstar = ({ Component, pageProps }) => {
  return (
    <>
      <Head>
        <title>G3 Roofing | Columbus Ohio</title>
      </Head>

      <SlideOutProvider>
        <Layout>
          <SlideOut />
          <Component {...pageProps} />
        </Layout>
      </SlideOutProvider>
      <Analytics />
    </>
  )
}

export default Rokstar
