import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import CustomSelectBox from './CustomSelectBox'
import { useSlideOut } from '../providers/SlideOutContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import va from '@vercel/analytics'

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('Full name is required'),
  emailAddress: Yup.string()
    .email('Invalid email address')
    .required('Email address is required'),
  phoneNumber: Yup.string()
    .matches(/^\d{10}$/, 'Invalid phone number format')
    .notRequired(),
  serviceName: Yup.string().required('Service is required'),
})

export default function SlideOut() {
  const { isOpen, toggleSlideOut } = useSlideOut()

  const formik = useFormik({
    initialValues: {
      fullName: '',
      emailAddress: '',
      phoneNumber: '',
      serviceName: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (formData) => {
      va.track('lead', {
        status: 'submitted',
      })
      try {
        // Send form data to the API
        const response = await fetch('/api/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        })

        // Validate the API response
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }

        // Reset Formik form
        formik.resetForm()

        // Show success message
        alert('Thank you! We will get back to you shortly.')
        va.track('lead', {
          status: 'successful',
        })
        // Free Estimate Conversion
        gtag('event', 'conversion', {
          send_to: 'AW-11017833032/oZtkCOTvy9gYEMiU24Up',
        })
        // Close slideout
        toggleSlideOut()
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
        va.track('lead', {
          status: 'error',
          error: error.message,
        })
        // Show error alert (You may want to replace this with a more user-friendly UI)
        alert('An error occurred. Please try again later.')
      }
    },
  })

  return (
    <Transition.Root show={isOpen} as={Fragment} className="z-50">
      <Dialog as="div" className="relative z-10" onClose={toggleSlideOut}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden bg-body/90">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-out duration-300 sm:duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-out duration-300 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <form
                    onSubmit={formik.handleSubmit}
                    className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                  >
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-white-dark px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="font-heading text-2xl font-semibold leading-6 text-body">
                            Request a free estimate
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              tabIndex={-1}
                              type="button"
                              className=" bg-white-dark text-body hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={toggleSlideOut}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="font-body text-xs font-medium text-white">
                            Provide the information below, and our team will get
                            back to you with a free, no-obligation estimate.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-3 pb-5 pt-6">
                            <div>
                              <label
                                htmlFor="full-name"
                                className="block font-heading text-sm font-medium leading-6 text-gray-900"
                              >
                                Full name
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="fullName"
                                  id="fullName"
                                  className="block w-full border-0 py-1.5 font-base text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white-dark sm:text-sm sm:leading-6"
                                  value={formik.values.fullName}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.fullName &&
                                  formik.errors.fullName && (
                                    <div className="pt-1 text-xs text-white-dark">
                                      {formik.errors.fullName}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="emailAddress"
                                className="block font-heading text-sm font-medium leading-6 text-gray-900"
                              >
                                Email address
                              </label>
                              <div className="mt-2">
                                <input
                                  type="email"
                                  name="emailAddress"
                                  id="emailAddress"
                                  className="block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white-dark sm:text-sm sm:leading-6"
                                  value={formik.values.emailAddress}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.emailAddress &&
                                  formik.errors.emailAddress && (
                                    <div className="pt-1 text-xs text-white-dark">
                                      {formik.errors.emailAddress}
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div>
                              <label
                                htmlFor="phoneNumber"
                                className="block font-heading text-sm font-medium leading-6 text-gray-900"
                              >
                                Phone number
                              </label>
                              <div className="mt-2">
                                <input
                                  type="tel"
                                  name="phoneNumber"
                                  id="phoneNumber"
                                  className="block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white-dark sm:text-sm sm:leading-6"
                                  value={formik.values.phoneNumber}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.phoneNumber &&
                                  formik.errors.phoneNumber && (
                                    <div className="pt-1 text-xs text-white-dark">
                                      {formik.errors.phoneNumber}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div>
                              <CustomSelectBox
                                label="Service"
                                formik={formik}
                              />
                              {formik.touched.serviceName &&
                                formik.errors.serviceName && (
                                  <div className="pt-1 text-xs text-white-dark">
                                    {formik.errors.serviceName}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end space-x-4 bg-body px-4 py-4">
                      <button
                        type="submit"
                        className="inline-flex justify-center bg-white-dark px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white-dark/75 focus:bg-white-dark/75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white-dark active:bg-white-dark/75"
                      >
                        Request Free Estimate
                      </button>
                      <button
                        type="button"
                        className="bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={toggleSlideOut}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
