import { createContext, useContext, useState } from 'react';

const SlideOutContext = createContext();

export const useSlideOut = () => {
  const context = useContext(SlideOutContext);
  if (!context) {
    throw new Error('useSlideOut must be used within a SlideOutProvider');
  }
  return context;
};

export const SlideOutProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleSlideOut = () => setIsOpen(!isOpen);

  return (
    <SlideOutContext.Provider value={{ isOpen, toggleSlideOut }}>
      {children}
    </SlideOutContext.Provider>
  );
};